// src/components/SpotList.js

import React, { useState, useEffect, useCallback } from 'react';
import SpotCard from './SpotCard';
import { searchSpots, searchSpotsHistorical } from '../services/api';
import {
  FaSearch,
  FaWind,
  FaSun,
  FaMapMarkerAlt,
  FaCloudSun,
  FaWater,
  FaGlobe,
  FaExclamationTriangle,
} from 'react-icons/fa';
import { motion } from 'framer-motion';

// Define loadingSteps outside the component to prevent re-creation on each render
const loadingSteps = [
  { icon: FaSearch, text: 'Analyzing your preferences...' },
  { icon: FaWind, text: 'Checking wind conditions...' },
  { icon: FaSun, text: 'Gathering temperature data...' },
  { icon: FaCloudSun, text: 'Looking at weather forecasts...' },
  { icon: FaWater, text: 'Examining precipitation levels...' },
  { icon: FaGlobe, text: 'Searching for kite spots worldwide...' },
  { icon: FaMapMarkerAlt, text: 'Finalizing your personalized spots...' },
];

function SpotList({ searchCriteria }) {
  const [allSpots, setAllSpots] = useState([]);
  const [displayedSpots, setDisplayedSpots] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [loadingProgress, setLoadingProgress] = useState(0);
  const [loadingStep, setLoadingStep] = useState(0);
  const [page, setPage] = useState(0);
  const spotsPerPage = 20;

  const fetchSpots = useCallback(async () => {
    setLoading(true);
    setError(null);
    setLoadingProgress(0);
    setLoadingStep(0);

    try {
      const totalSteps = loadingSteps.length;
      const progressIncrement = 100 / totalSteps;
      const intervalDuration = 500; // milliseconds

      const intervalId = setInterval(() => {
        setLoadingProgress((prev) => {
          const newProgress = prev + progressIncrement;
          return newProgress >= 100 ? 100 : newProgress;
        });
        setLoadingStep((prev) => (prev + 1) % totalSteps);
      }, intervalDuration);

      let response;
      const todayDate = new Date().toISOString().split('T')[0];
      if (searchCriteria.end_date <= todayDate) {
        // Historical search
        response = await searchSpotsHistorical(searchCriteria);
      } else {
        // Forecast search
        response = await searchSpots(searchCriteria);
      }

      clearInterval(intervalId);
      setLoadingProgress(100);

      setAllSpots(response.spots);
      setDisplayedSpots(response.spots.slice(0, spotsPerPage));
      setPage(1);
    } catch (error) {
      console.error('Error fetching spots:', error);
      if (error.status === 404) {
        setError('No spots found matching your criteria');
      } else {
        setError('Failed to fetch spots. Please try again.');
      }
    }

    setLoading(false);
  }, [searchCriteria, loadingSteps.length]); // Added loadingSteps.length

  useEffect(() => {
    fetchSpots();
  }, [searchCriteria, fetchSpots]);

  const loadMoreSpots = useCallback(() => {
    const nextPage = page + 1;
    const newSpots = allSpots.slice(0, nextPage * spotsPerPage);
    setDisplayedSpots(newSpots);
    setPage(nextPage);
  }, [page, allSpots]);

  useEffect(() => {
    const handleScroll = () => {
      if (
        window.innerHeight + document.documentElement.scrollTop >=
        document.documentElement.offsetHeight - 100
      ) {
        if (displayedSpots.length < allSpots.length) {
          loadMoreSpots();
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [displayedSpots, allSpots, loadMoreSpots]);

  if (loading) {
    return (
      <div className="flex flex-col items-center justify-center h-64">
        <motion.div
          className="w-full max-w-md px-4"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
        >
          <div className="flex items-center mb-4">
            {React.createElement(loadingSteps[loadingStep].icon, {
              className: 'text-5xl text-primary mr-4',
            })}
            <p className="text-lg text-primary">
              {loadingSteps[loadingStep].text}
            </p>
          </div>
          <div className="w-full bg-gray-200 rounded-full h-2.5">
            <motion.div
              className="bg-primary h-2.5 rounded-full"
              initial={{ width: '0%' }}
              animate={{ width: `${loadingProgress}%` }}
              transition={{ duration: 0.5 }}
            />
          </div>
        </motion.div>
      </div>
    );
  }

  if (error) {
    const suggestions = [
      "Try widening your wind speed or temperature range",
      "Extend your date range or consider a different time of year",
      "Expand your search to include more countries or regions",
      "Reduce the minimum duration of suitable conditions"
    ];

    return (
      <div className="max-w-2xl mx-auto px-4 py-16 sm:px-6 sm:py-24 lg:px-8">
        <div className="bg-white shadow-lg rounded-lg overflow-hidden">
          <div className="bg-yellow-50 px-4 py-5 sm:p-6">
            <div className="flex items-center justify-center">
              <FaExclamationTriangle className="h-12 w-12 text-yellow-400" />
            </div>
            <h3 className="mt-5 text-center text-2xl leading-6 font-medium text-gray-900">{error}</h3>
            <p className="mt-2 text-center text-sm text-gray-500">
              We couldn't find any kite spots that meet your specific conditions. Here are some suggestions:
            </p>
          </div>
          <div className="px-4 py-5 sm:p-6">
            <ul className="mt-3 list-disc list-inside text-sm text-gray-600 space-y-2">
              {suggestions.map((suggestion, index) => (
                <li key={index} className="flex items-start">
                  <span className="h-6 flex items-center sm:h-7">
                    <svg className="flex-shrink-0 h-5 w-5 text-cyan-500" viewBox="0 0 20 20" fill="currentColor">
                      <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                    </svg>
                  </span>
                  <p className="ml-2">{suggestion}</p>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    );
  }

  return (
    <section className="py-8">
      <h2 className="text-3xl font-bold text-center mb-6">Kite Spots Matching Your Search</h2>
      {displayedSpots.length === 0 ? (
        <p className="text-center text-xl">No spots found matching your criteria.</p>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {displayedSpots.map((spot) => (
            <SpotCard
              key={spot.id}
              spot={spot}
              travelDates={{
                startDate: searchCriteria.start_date,
                endDate: searchCriteria.end_date,
              }}
              userPreferences={{
                minWindSpeed: searchCriteria.min_wind_speed,
                maxWindSpeed: searchCriteria.max_wind_speed,
                minTemperature: searchCriteria.min_temperature,
                maxTemperature: searchCriteria.max_temperature,
                timeRangeStart: searchCriteria.time_range_start,
                timeRangeEnd: searchCriteria.time_range_end,
                minDuration: searchCriteria.min_duration,
              }}
              isHistorical={
                spot.weather_data &&
                spot.weather_data.length > 0 &&
                spot.weather_data[0].is_forecast === false
              }
            />
          ))}
        </div>
      )}
      {displayedSpots.length < allSpots.length && (
        <div className="flex justify-center items-center mt-8">
          <button onClick={loadMoreSpots} className="bg-primary text-white px-4 py-2 rounded">
            Load More
          </button>
        </div>
      )}
    </section>
  );
}

export default SpotList;
