// src/components/SearchForm.js

import React, { useState, useEffect } from 'react';
import {
  FaCalendarAlt,
  FaSearch,
} from 'react-icons/fa';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { getCountries, getContinents } from '../services/api';
import Select from 'react-select';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import Tooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap.css';

function SearchForm({ onSearch }) {
  const [searchParams, setSearchParams] = useState({
    start_date: new Date(),
    end_date: new Date(new Date().setDate(new Date().getDate() + 7)),
    wind_speed: [15, 25],
    temperature: [22, 32],
    time_range: [9, 17],
    min_duration: 2,
    countries: [],
    continents: [],
  });

  const [countries, setCountries] = useState([]);
  const [continents, setContinents] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const countriesData = await getCountries();
      const continentsData = await getContinents();

      const formattedCountries = countriesData.map((country) => ({
        value: country,
        label: country,
      }));
      const formattedContinents = continentsData.map((continent) => ({
        value: continent,
        label: continent,
      }));

      setCountries(formattedCountries);
      setContinents(formattedContinents);
    };
    fetchData();
  }, []);

  const handleChange = (name, value) => {
    setSearchParams((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSearch({
      ...searchParams,
      start_date: searchParams.start_date.toISOString().split('T')[0],
      end_date: searchParams.end_date.toISOString().split('T')[0],
      min_wind_speed: searchParams.wind_speed[0],
      max_wind_speed: searchParams.wind_speed[1],
      min_temperature: searchParams.temperature[0],
      max_temperature: searchParams.temperature[1],
      time_range_start: searchParams.time_range[0],
      time_range_end: searchParams.time_range[1],
      countries: searchParams.countries.map((country) => country.value),
      continents: searchParams.continents.map((continent) => continent.value),
    });
  };

  // Custom renderThumb function for tooltips
  const renderThumb = (props, state) => {
    return (
      <Tooltip
        overlay={`${state.value}`}
        visible={state.dragging}
        placement="top"
        key={props.key}
      >
        <div {...props} />
      </Tooltip>
    );
  };

  return (
    <form id="search-form" onSubmit={handleSubmit} className="bg-white">
      {/* Date Range */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div>
          <label htmlFor="start_date" className="text-gray-700 mb-1">
            Start Date
          </label>
          <div className="relative">
            <FaCalendarAlt className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
            <DatePicker
              selected={searchParams.start_date}
              onChange={(date) => handleChange('start_date', date)}
              minDate={new Date()}
              className="w-full pl-10 pr-3 py-2 rounded-lg border border-gray-300 outline-none focus:border-primary"
              dateFormat="yyyy-MM-dd"
            />
          </div>
        </div>
        <div>
          <label htmlFor="end_date" className="text-gray-700 mb-1">
            End Date
          </label>
          <div className="relative">
            <FaCalendarAlt className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
            <DatePicker
              selected={searchParams.end_date}
              onChange={(date) => handleChange('end_date', date)}
              minDate={searchParams.start_date}
              className="w-full pl-10 pr-3 py-2 rounded-lg border border-gray-300 outline-none focus:border-primary"
              dateFormat="yyyy-MM-dd"
            />
          </div>
        </div>
      </div>

      {/* Wind Speed and Temperature Sliders */}
      <div className="mt-4 grid grid-cols-1 md:grid-cols-2 gap-4">
        <div>
          <label htmlFor="wind_speed" className="text-gray-700 mb-1 block">
            Wind Speed (knots): {searchParams.wind_speed[0]} - {searchParams.wind_speed[1]} kn
          </label>
          <Slider
            range
            min={0}
            max={50}
            value={searchParams.wind_speed}
            onChange={(value) => handleChange('wind_speed', value)}
            renderThumb={renderThumb}
            railStyle={{ backgroundColor: '#e2e8f0' }}
            trackStyle={[{ backgroundColor: '#3182ce' }]}
            handleStyle={[
              { borderColor: '#3182ce' },
              { borderColor: '#3182ce' },
            ]}
          />
        </div>
        <div>
          <label htmlFor="temperature" className="text-gray-700 mb-1 block">
            Temperature (°C): {searchParams.temperature[0]}°C - {searchParams.temperature[1]}°C
          </label>
          <Slider
            range
            min={-10}
            max={50}
            value={searchParams.temperature}
            onChange={(value) => handleChange('temperature', value)}
            renderThumb={renderThumb}
            railStyle={{ backgroundColor: '#e2e8f0' }}
            trackStyle={[{ backgroundColor: '#38a169' }]}
            handleStyle={[
              { borderColor: '#38a169' },
              { borderColor: '#38a169' },
            ]}
          />
        </div>
      </div>

      {/* Time Range and Minimum Duration */}
      <div className="mt-4 grid grid-cols-1 md:grid-cols-2 gap-4">
        <div>
          <label htmlFor="time_range" className="text-gray-700 mb-1 block">
            Time Range (hours): {searchParams.time_range[0]}:00 - {searchParams.time_range[1]}:00
          </label>
          <Slider
            range
            min={0}
            max={23}
            value={searchParams.time_range}
            onChange={(value) => handleChange('time_range', value)}
            renderThumb={renderThumb}
            railStyle={{ backgroundColor: '#e2e8f0' }}
            trackStyle={[{ backgroundColor: '#d69e2e' }]}
            handleStyle={[
              { borderColor: '#d69e2e' },
              { borderColor: '#d69e2e' },
            ]}
          />
        </div>
        <div>
          <label htmlFor="min_duration" className="text-gray-700 mb-1">
            Minimum Duration (hours)
          </label>
          <input
            type="number"
            value={searchParams.min_duration}
            onChange={(e) =>
              handleChange('min_duration', parseInt(e.target.value))
            }
            className="w-full px-3 py-2 rounded-lg border border-gray-300 outline-none focus:border-primary"
            min="1"
            max="24"
          />
        </div>
      </div>

      {/* Countries and Continents */}
      <div className="mt-4 grid grid-cols-1 md:grid-cols-2 gap-4">
        <div>
          <label htmlFor="countries" className="text-gray-700 mb-1">
            Countries
          </label>
          <Select
            isMulti
            options={countries}
            value={searchParams.countries}
            onChange={(selected) => handleChange('countries', selected || [])}
            placeholder="Select countries..."
            className="react-select-container"
            classNamePrefix="react-select"
          />
        </div>
        <div>
          <label htmlFor="continents" className="text-gray-700 mb-1">
            Continents
          </label>
          <Select
            isMulti
            options={continents}
            value={searchParams.continents}
            onChange={(selected) => handleChange('continents', selected || [])}
            placeholder="Select continents..."
            className="react-select-container"
            classNamePrefix="react-select"
          />
        </div>
      </div>

      {/* Search Button */}
      <div className="mt-4 text-right">
        <button
          type="submit"
          className="w-full md:w-auto bg-primary text-white px-6 py-3 rounded-lg font-semibold hover:bg-primary-dark transition"
        >
          <FaSearch className="inline-block mr-2" />
          Search
        </button>
      </div>
    </form>
  );
}

export default SearchForm;