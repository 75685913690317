import React, { useState, useEffect } from 'react';
import { FaEnvelope } from 'react-icons/fa';

const EmailCapture = () => {
  const [email, setEmail] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [message, setMessage] = useState('');
  const [isHidden, setIsHidden] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const pageHeight = document.documentElement.scrollHeight - window.innerHeight;
      const scrollThreshold = 0.5; // Show after scrolling 50% of the page

      setIsHidden(scrollPosition <= pageHeight * scrollThreshold);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setMessage('');
  
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/subscribe`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });
  
      if (response.ok) {
        const data = await response.json();
        setMessage(data.message);
        setEmail('');
        setTimeout(() => setIsHidden(true), 3000);
      } else {
        const errorData = await response.json();
        throw new Error(errorData.detail || 'Subscription failed');
      }
    } catch (error) {
      console.error('Submission error:', error);
      setMessage('Oops! Something went wrong. Please try again.');
    }
  
    setIsSubmitting(false);
  };

  if (isHidden) return null;

  return (
    <div className="fixed bottom-0 inset-x-0 bg-white shadow-lg p-3 md:p-4 z-30">
      <div className="container mx-auto px-4 flex flex-col md:flex-row items-center justify-between">
        <div className="flex items-center mb-3 md:mb-0 w-full md:w-auto">
          <FaEnvelope className="text-primary text-xl md:text-2xl mr-3" />
          <div>
            <h3 className="text-base md:text-lg font-semibold text-gray-800">
              Stay Ahead: Kite Spot Alerts Are On The Way!
            </h3>
            <p className="text-xs md:text-sm text-gray-600">
              Sign up now and be among the first to access real-time kite spot updates.
            </p>
          </div>
        </div>
        <form onSubmit={handleSubmit} className="flex w-full md:w-auto">
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Your email"
            className="w-full md:w-auto px-3 py-2 text-sm border border-gray-300 rounded-l-md focus:outline-none focus:ring-2 focus:ring-primary"
            required
          />
          <button
            type="submit"
            className="bg-primary text-white px-4 py-2 text-sm rounded-r-md hover:bg-primary-dark transition whitespace-nowrap"
            disabled={isSubmitting}
          >
            {isSubmitting ? 'Joining...' : 'Join Waitlist'}
          </button>
        </form>
      </div>
      {message && (
        <p className="text-center mt-2 text-sm text-green-600">{message}</p>
      )}
    </div>
  );
};

export default EmailCapture;