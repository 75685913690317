// src/components/HeroSearch.js
import React from 'react';
import SearchForm from './SearchForm';

function HeroSearch({ onSearch }) {
  return (
    <section className="py-4 mt-4">
      <div className="text-left mb-6">
        <h1 className="text-2xl md:text-3xl font-bold text-primary">
          Find the Best Kitesurfing Spots Based on Your Ideal Conditions
        </h1>
        <h2 className="mt-4 text-lg text-gray-700">
          Set your preferred wind speed, temperature, and travel dates to discover perfect kitesurfing destinations worldwide.
        </h2>
      </div>
      <SearchForm onSearch={onSearch} />
    </section>
  );
}

export default HeroSearch;