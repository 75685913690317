import React from 'react';
import { FaTwitter, FaWind, FaHeart, FaSmile } from 'react-icons/fa';
import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet';

function About() {
  return (
    <div className="container mx-auto px-4 py-16">
      <Helmet>
        <title>About kiteCOMPASS | Your Ultimate Kitesurfing Spot Finder</title>
        <meta name="description" content="Learn about kiteCOMPASS and how we help you discover the best kitesurfing locations worldwide. Meet Max, the kitesurfer and coder behind the platform." />
        <link rel="canonical" href="https://www.kitecompass.app/about" />
      </Helmet>

      <motion.h1
        className="text-4xl font-bold mb-6 text-center text-primary"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        About kiteCOMPASS & Me
      </motion.h1>

      <div className="flex flex-col md:flex-row items-start mb-12">
        <img
          src="/images/max.jpg"
          alt="Max Fischer"
          className="w-64 h-64 object-cover rounded-full mb-6 md:mb-0 md:mr-8 shadow-lg"
        />
        <div>
          <h2 className="text-3xl font-semibold mb-4">Hi, I'm Max!</h2>
          <p className="mb-4 text-lg leading-relaxed">
            I'm a kitesurfer, coder, and the creator of kiteCompass. When I'm not chasing the wind or wrestling with code, I'm probably daydreaming about both.
          </p>
          <a
            href="https://x.com/intent/follow?screen_name=iammxfschr"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-500 hover:underline flex items-center text-lg"
          >
            <FaTwitter className="mr-2" />
            Follow me on Twitter
          </a>
        </div>
      </div>

      <section className="mb-12">
        <h3 className="text-2xl font-semibold mb-4 flex items-center">
          <FaHeart className="text-red-500 mr-2" />
          My Kitesurfing Love Story
        </h3>
        <p className="mb-4 text-lg leading-relaxed">
          Once upon a time, I tried to impress a girl by learning kitesurfing. Spoiler alert: she wasn't impressed, but I fell in love—with kitesurfing, that is!
        </p>
        <p className="text-lg leading-relaxed">
          Fast forward, and kitesurfing became my passion. There's something magical about harnessing the wind and dancing on the waves. Plus, it looks cool.
        </p>
      </section>

      <section className="mb-12">
        <h3 className="text-2xl font-semibold mb-4 flex items-center">
          <FaWind className="text-primary mr-2" />
          The Birth of kiteCOMPASS
        </h3>
        <p className="mb-4 text-lg leading-relaxed">
          Planning kite trips used to be a headache—endless tabs, conflicting forecasts, and a lot of guesswork. So, I thought, "Why not create something to make this easier?"
        </p>
        <p className="text-lg leading-relaxed">
          Enter kiteCOMPASS, your personal kitesurfing spot finder. Set your ideal conditions, and we'll do the heavy lifting. Less planning, more kiting!
        </p>
      </section>

      <section className="mb-12">
        <h3 className="text-2xl font-semibold mb-4 flex items-center">
          <FaSmile className="text-primary mr-2" />
          Let's Make Waves Together
        </h3>
        <p className="mb-4 text-lg leading-relaxed">
          I'm all about making kiteCOMPASS the best it can be. Your feedback means the world to me (almost as much as a perfect 20-knot wind).
        </p>
        <p className="mb-4 text-lg leading-relaxed">
          Love kiteCOMPASS? Spread the word! Found a bug or have an idea? I'd love to hear from you.
        </p>
        <p className="text-lg leading-relaxed">
          Let's connect and make the kitesurfing world a bit more awesome together!
        </p>
        <a
          href="https://x.com/intent/follow?screen_name=iammxfschr"
          target="_blank"
          rel="noopener noreferrer"
          className="mt-4 inline-block bg-primary text-white px-6 py-3 rounded-full font-bold hover:bg-primary-dark transition"
        >
          <FaTwitter className="inline-block mr-2" />
          Follow @iammxfschr
        </a>
      </section>
    </div>
  );
}

export default About;
