import React, { useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Header from './components/Header';
import Footer from './components/Footer';
import SpotList from './components/SpotList';
import HeroSearch from './components/HeroSearch';
import About from './components/About';
import EmailCapture from './components/EmailCapture';
import AnnouncementBanner from './components/AnnouncementBanner';
import TermsOfService from './components/TermsOfService';
import PrivacyPolicy from './components/PrivacyPolicy';

function App() {
  const [searchCriteria, setSearchCriteria] = useState(null);

  const handleSearch = (criteria) => {
    setSearchCriteria(criteria);
  };

  const schemaMarkup = {
    "@context": "http://schema.org",
    "@type": "WebSite",
    "name": "kiteCOMPASS",
    "url": "https://www.kitecompass.app",
    "potentialAction": {
      "@type": "SearchAction",
      "target": "https://www.kitecompass.app/search?q={search_term_string}",
      "query-input": "required name=search_term_string"
    }
  };

  return (
    <div className="flex flex-col min-h-screen bg-white text-gray-900">
      <Helmet>
        <title>kiteCOMPASS | Discover Perfect Kitesurfing Locations Worldwide</title>
        <meta name="description" content="Find ideal kitesurfing spots based on wind conditions, temperature, and travel dates. Plan your perfect kite trip with kiteCOMPASS." />
        <link rel="canonical" href="https://www.kitecompass.app" />
        <script type="application/ld+json">
          {JSON.stringify(schemaMarkup)}
        </script>
      </Helmet>
      <AnnouncementBanner />
      <Header />
      <main className="flex-grow container mx-auto px-4 py-4">
        <Routes>
          <Route
            path="/"
            element={
              <>
                <HeroSearch onSearch={handleSearch} />
                {searchCriteria && <SpotList searchCriteria={searchCriteria} />}
              </>
            }
          />
          <Route path="/about" element={<About />} />
          <Route path="/terms" element={<TermsOfService />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />
        </Routes>
      </main>
      <Footer />
      <EmailCapture />
    </div>
  );
}

export default App;